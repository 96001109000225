<template>
  <div>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>子机构列表</span>
      </div>
      <div slot="extra">
        <a-button v-if="$auth('mechanism_add') && !userMechanismLevel" type="primary" shape="round" @click="onAdd"><a-icon type="plus" />添加子机构</a-button>
      </div>
      <a-table
        rowKey="id"
        :data-source="list"
        :pagination="pagination"
        :loading="listLoading"
        @change="handleTableChange"
      >
        <a-table-column key="name" title="机构名称" align="center" data-index="name" width="120px" />
        <a-table-column key="parentName" title="上级机构" align="center" data-index="parentName" width="100px">
          <template slot-scope="parentName">
            <span>
              {{ parentName ? parentName : '-' }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="cellphone" title="联系电话" align="center" data-index="cellphone" width="120px" />
        <a-table-column key="studentCount" title="学生总数" align="center" data-index="studentCount" width="120px" />
        <a-table-column key="userCount" title="员工总数" align="center" data-index="userCount" width="120px" />
        <a-table-column key="userlist" title="机构管理" align="center" width="120px">
          <template slot-scope="record">
            <a-button type="link" size="small" @click="onUserList(record)">人员列表</a-button>
          </template>
        </a-table-column>
        <a-table-column key="actions" title="操作" align="center" width="140px">
          <template slot-scope="record">
            <a-space>
              <a-button type="link" size="small" v-if="$auth('mechanism_update')" @click="onEdit(record)"><a-icon type="edit" />编辑</a-button>
              <a-button type="link" size="small" v-if="$auth('mechanism_delete')" @click="handleRemove(record)" class="error-color"
                ><a-icon type="delete" />删除</a-button
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
    <Model ref="model" :dataSource="modalData" />
  </div>
</template>

<script>
import * as mechanismApi from '@/api/mechanism'
import Model from '../components/Model'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
const modalData = {
  type: 'ADD',
  name: '',
  email: '',
  parentId: '',
  status: '',
  studentRegistrationLimit: '1',
  userRegistrationLimit: '1',
  mechanismRegistrationLimit: '0'
}
export default {
  data() {
    return {
      listLoading: false,
      list: [],
      query: {},
      pagination: {},
      modalData: {}
    }
  },
  components: {
    Model
  },
  computed: {
    ...mapGetters({
      userMechanismLevel: 'getUserMechanismLevel'
    })
  },

  mounted() {
    this.initData()
  },
  methods: {
    initData(query = {}) {
      const {
        $route: {
          params: { id }
        }
      } = this
      this.query = { parentId: id }
      this.pagination = this.initPagination()
      return this.getData()
    },
    getData() {
      this.listLoading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      return mechanismApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      getData()
    },
    onAdd() {
      const {
        $refs: { model },
        $nextTick,
        initData,
        $route: {
          params: { id }
        }
      } = this
      this.modalData = cloneDeep(modalData)
      this.modalData.type = 'ADD_CHILDRED'
      this.modalData.parentId = Number(id)

      $nextTick(() =>
        model.showModal(e => {
          initData()
        })
      )
    },
    onEdit(record) {
      const {
        $refs: { model },
        $nextTick,
        initData
      } = this
      mechanismApi.detail(record.id).then(res => {
        const {
          name,
          id,
          parent,
          email,
          status,
          cellphone,
          userRegistrationLimit,
          studentRegistrationLimit,
          mechanismRegistrationLimit
        } = res.data
        this.modalData = {
          type: 'ADD_CHILDRED',
          name,
          id,
          parentId: parent.id,
          email,
          status,
          cellphone,
          userRegistrationLimit,
          studentRegistrationLimit,
          mechanismRegistrationLimit
        }
        $nextTick(() => {
          model.showModal(e => {
            initData()
          })
        })
      })
    },
    onUserList(record) {
      this.$router.push({ path: `/permission/organization/userlist/${record.id}` })
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return mechanismApi
            .remove(record.id)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
